<template>
  <div class="billingUpload">
    <div class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-1">{{ title }}</h1>
        </div>
      </div>
    </div>
    <div class="container">
      <v-card :loading="loading" class="mb-4 pa-4">
        <v-container>
          <v-row>
            <v-col cols="3">
              <v-select
                v-model="invoiceNumberSelected"
                :items="invoiceNumbersList"
                item-text="invoice_number"
                item-value="invoice_number"
                dense
                outlined
                menu-props="auto"
                label="Select an Invoice Number"
              ></v-select>
            </v-col>
            <company-select
                @chosen="setCompany"
                v-model="companySelected"
                :colWidth="5"
              ></company-select>
          
             <v-col cols="4">
                 <v-btn
                    color="primary"
                 
                    :disabled="loading || !invoiceNumberSelected"
                    @click="getUncommittedBillingData()"
                    >Load Uncommitted Data</v-btn
                    >
            </v-col>
            <v-col cols="3">
                <v-select
                v-model="chargeCode"
                :items="[4003,4004,4005,4006,4007,4008,4010,4011,4012,4014,4018,4019]"
                dense
                outlined
                menu-props="auto"
                label="Select a charge code"
              ></v-select>
            </v-col>
            <v-col cols="5">
                  <v-text-field
                  outlined
                  dense
                  label="Client Reference"
                  type="text"
                  v-model="clientReference"
                ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-btn
                    color="red"
                   
                    :disabled="loading || !invoiceNumberSelected || !chargeCode || !clientReference || !companySelected.id"
                    @click="deleteBillingDataByClientReference()"
                    >Delete Billing Data By Client Reference</v-btn
                    >
            </v-col>
              <v-col cols="12">
                <p><b>- To delete all uncommited data:</b> First load the data for the invoice so it can be checked, then an option will appear to delete it, if no company is selected, it is applied for every company for the specified invoice</p>
                 <p><b>- To delete data by client reference:</b> Select invoice number, company and charge code, then input the client refence, press the delete button and confirm. If its successful, the client reference field will clear and the process can be repeated for other client references</p>
            </v-col>
          </v-row>
        </v-container>
      </v-card>

      <v-card class="mb-4 pa-4" v-if="loadedInvoiceNumber">
        <v-row>
          <v-col cols="12">
            <h1>
              Uncommitted Billing Data For Invoice {{ loadedInvoiceNumber }} For
              {{ companySelected.name ?  companySelected.name : "All Companies"  }}
            </h1>
          </v-col>
        </v-row>
        <v-row v-if="data.length > 0">
        
          <!-- <v-col cols="6">
            <ExportButtons :headers="dataHeaders" :data="data" :title="title" />
          </v-col> -->
          <v-col cols="6">
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
            <v-col cols="6"  align="right">
               <v-btn
                    color="red"
                    class="mb-2 ml-2"
                    :disabled="loading || !loadedInvoiceNumber"
                    outlined
                    @click="deleteUncommittedBillingData()"
                    >Delete Data For Invoice {{ loadedInvoiceNumber }} For
              {{ companySelected.name ?  companySelected.name : "All Companies"  }}</v-btn
                    >
          </v-col>  
          <v-col cols="12">
            <v-data-table
              :headers="dataHeaders"
              :items="data"
              :items-per-page="5"
              :search="search"
            >
            </v-data-table>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12"> No records found </v-col>
        </v-row>
      </v-card>
    </div>
    <v-snackbar v-model="snackbar" :timeout="5000">
      {{ text }}
      <v-btn color="blue" text @click="snackbar = false"></v-btn>
    </v-snackbar>
  </div>
</template>
<script>
import BillingSnap from "@/services/BillingSnap.js";
// import ExportButtons from "@/components/ExportButtons";
import CompanySelect from "../components/forms/CompanySelect";
export default {
  name: "CompanyBillingCredit",
  components: {  CompanySelect },
  data() {
    return {
      title: "Delete Uncommitted Billing Data",
      // Snackbar
      snackbar: false,
      text: "",
      response: null,

      search: "",
      loading: false,

      invoiceNumbersList: [],

      companySelected: {},
      invoiceNumberSelected: "",
      chargeCode: "",
      clientReference: "",

      loadedCompany: {},
      loadedInvoiceNumber: "",
      data: [],
      dataHeaders: [
        { text: "Id", value: "id" },
        { text: "Company Id", value: "company_id" },
        { text: "Prep Id", value: "prep_id" },
        { text: "Company Code", value: "company_code" },
        { text: "Charge Date", value: "charge_date" },
        { text: "Charge Type", value: "charge_type" },
        { text: "Reference", value: "reference" },
        { text: "Client Reference", value: "client_reference" },
        { text: "UOM", value: "UOM" },
        { text: "Billable Units", value: "billable_units" },
        { text: "Tariff", value: "tariff" },
        { text: "Total Charge", value: "total_charge" },
        { text: "Charge Code", value: "charge_code" },
        { text: "invoice_number", value: "invoice_number" },
        { text: "Committed", value: "committed" },
      ],
    };
  },
  created() {
    this.getUncommittedInvoiceNumbers();
  },

  methods: {
    getUncommittedInvoiceNumbers() {
      this.loading = true;
      BillingSnap.getUncommittedInvoiceNumbers()
        .then(
          ((invoiceNumbers) => {
            this.$set(this, "invoiceNumbersList", invoiceNumbers);
            this.loading = false;
            this.snackbar = true;
            this.text = `Successfully loaded invoice numbers`;
          }).bind(this)
        )
        .catch((error) => {
          this.snackbar = true;
          this.text = `${error.response.data.message}`;
          this.loading = false;
          return;
        });
    },
    resetData() {
      this.loadedCompany = {};
      this.loadedInvoiceNumber = "";
      this.data = [];
    },
    setCompany(company) {
      this.companySelected = company;
    },
    deleteUncommittedBillingData(){
        let comp = this.loadedCompany.name ? this.loadedCompany.name  : 'all companies'
        let companyId = this.loadedCompany.id ? this.loadedCompany.id : ''
        let invoiceNumber = this.loadedInvoiceNumber
        let confirmMsg = `Are you sure you want to delete all uncomitted data for the invoice ${invoiceNumber} for ${comp} ?`
        if (confirm(confirmMsg)) {
        BillingSnap.deleteUncommittedBillingData({ invoiceNumber: invoiceNumber, companyId: companyId })
          .then(response => {
            this.snackbar = true;
            this.text = response;
            this.getUncommittedInvoiceNumbers();
            this.getUncommittedBillingData()
            this.loading = false;
          })
          .catch((error) => {
            this.snackbar = true;
            this.text = `${error.response.data.message}`;
            this.loading = false;
            return;
          });
      }
    },

    deleteBillingDataByClientReference(){
        let comp = this.companySelected.name ? this.companySelected.name  : 'all companies'
        let companyId = this.companySelected.id ? this.companySelected.id : ''
        let invoiceNumber = this.invoiceNumberSelected
        let chargeCode= this.chargeCode
        let clientReference = this.clientReference
        let confirmMsg = `Are you sure you want to delete all records for invoice ${invoiceNumber} for ${comp} - charge code: ${chargeCode} with client reference: ${clientReference}?`
        if (confirm(confirmMsg)) {
        BillingSnap.deleteBillingDataByClientReference({ invoiceNumber: invoiceNumber, companyId: companyId,chargeCode: chargeCode,  clientReference: clientReference})
          .then(response => {
            this.snackbar = true;
            this.text = response;
            this.loading = false;
            this.clientReference = ''
          })
          .catch((error) => {
            this.snackbar = true;
            this.text = `${error.response.data.message}`;
            this.loading = false;
            return;
          });
      }
    },

    async getUncommittedBillingData() {
      this.loading = true;
      this.resetData();
      let companyId = this.companySelected.id ?? "";
      BillingSnap.getUncommittedBillingData(
        this.invoiceNumberSelected,
        companyId
      )
        .then(
          ((data) => {
            this.$set(this, "data", data);
            this.loading = false;
            this.snackbar = true;
            this.text = `Successfully Loaded Data`;
            this.loadedCompany = this.companySelected;
            this.loadedInvoiceNumber = this.invoiceNumberSelected;
          }).bind(this)
        )
        .catch((error) => {
          this.snackbar = true;
          this.text = `${error.response.data.message}`;
          this.loading = false;
          return;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.description {
  margin-bottom: 30px;
}
.error {
  background-color: red;
}
h1 {
  font-size: 120%;
  font-weight: bold;
}
</style>